import { mapActions } from 'vuex'

export default {
  computed: {
    isAnchorLinkSignup() {
      return Boolean(this.cta?.href?.match(/^#signup-campaign$/i))
    },
  },

  methods: {
    ...mapActions('modalSignup', ['setSignupModalOpen']),

    openModal() {
      document.body.classList.toggle('modal-open')
      this.setSignupModalOpen(true)
    },
  },
}
