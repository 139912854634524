import { IconValidator } from '~/validators'
import { parseLink } from '@/services/parselinkService'

export default {
  props: {
    href: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    leftIcon: {
      type: Object,
      validator: prop => IconValidator(prop, 'leftIcon'),
    },

    centerIcon: {
      type: Object,
      validator: prop => IconValidator(prop, 'centerIcon'),
    },

    rightIcon: {
      type: Object,
      validator: prop => IconValidator(prop, 'rightIcon'),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    scrollToOptions: {
      type: Object,
    },

    ariaLabel: {
      type: String,
    },
  },

  computed: {
    hasLabel() {
      return this.$slots.default || this.label ? true : false
    },

    hasLeftIcon() {
      return this.leftIcon && Object.keys(this.leftIcon).length > 0
    },

    hasCenterIcon() {
      return this.centerIcon && Object.keys(this.centerIcon).length > 0
    },

    hasRightIcon() {
      return this.rightIcon && Object.keys(this.rightIcon).length > 0
    },

    tabIndex() {
      if (this.disabled) return -1
    },

    computedHref() {
      const excludeSubString = [
        'solutions.',
        'hotm.art',
        'comunidade.',
        'comunidad.',
        'desafio30dias.',
        'club.',
        'sparkle.',
        'ethicsline.',
        'atendimento.',
        'help.',
        'campbrasil.',
        'guide.',
        '/marketplace',
        'app-vlc.',
        'press.',
        'webinars.',
        'facebook.',
        'twitter.',
        'x.',
        'linkedin.',
        'youtube.',
        'instagram.',
        'pinterest',
        'tiktok.',
        'sparkleapp.',
        'kenoby.',
        'esg.',
        'canalconfidencial.',
        'developers.',
        'purchase.',
        'consumer.',
        'apps.apple.',
        'play.google.',
        'mkt-tracking.',
        'extensoes.',
        'scd.',
        'app.',
        'pay',
        'art.klickpages',
        'signup.',
        'sso.',
        'hotmart.co/',
        'festival',
        'academy',
        '#test7-form',
        'firesessions',
        'contatoseguro.',
        'pro.',
        'trends.',
        'campaign.',
        '#signup-hero',
        '#signup-campaign',
        'fire.',
      ]
      if (!this.href) return
      if (
        this.href.match(/^javascript:void\(0\)\;?$/im) ||
        this.href.match(/^mailto/im) ||
        excludeSubString.some(i => this.href.includes(i))
      )
        return `${this.href}`

      const routerBaseWithoutLastSlash = this.$router.options.base.replace(/\/$/, '')

      return parseLink(`${routerBaseWithoutLastSlash}${this.href}`)
    },
  },

  methods: {
    click(e) {
      if (this.disabled) return false

      this.$listeners.hasOwnProperty('click')
        ? this.$listeners.click(e)
        : this.href.match(/^https?/i)
          ? false
          : this.navigate(e)
    },

    navigate(e) {
      e.preventDefault()

      let url = this.href

      if (url.match(/^javascript:void\(0\)\;?|#$/i)) return false

      if (url.match(/^#.*$/i) && url.length > 1) {
        const element = document.querySelector(url)

        if (element) this.$scrollTo(element, this.scrollToOptions)

        return
      }

      const path = url.replace(/(\/$)/gi, '').replace(/\/\?/gi, '?') || `/${this.getLocale}`

      if (path !== this.$route.path) this.$router.push({ path })
    },
  },
}
